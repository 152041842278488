import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129')
];

export const server_loads = [0];

export const dictionary = {
		"/(authedOnly)/(client)": [86,[3,13],[,14]],
		"/(authedOnly)/(admin)/admin": [33,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/clients": [35,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]": [36,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/cch": [37,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/documents": [38,[3,4,6,7],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/entities": [39,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/memberships": [40,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/profile": [41,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/strategies": [42,[3,4,6,8],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/strategies/[year=integer]": [43,[3,4,6,8],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/strategies/[year=integer]/quarterly-estimated-payments/[strategyId=uuid]": [45,[3,4,6,8],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/strategies/[year=integer]/[slug]/[strategyId=uuid]": [44,[3,4,6,8],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/task-lists": [46,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/task-lists/[taskListId=uuid]": [47,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/tax-analysis-model": [48,[3,4,6],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/tax-filing": [49,[3,4,6,9],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/tax-filing/[year=integer]": [50,[3,4,6,9],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/tax-filing/[year=integer]/get-started": [52,[3,4,6,9],[,5]],
		"/(authedOnly)/(admin)/admin/clients/[clientId=uuid]/tax-filing/[year=integer]/[taxFilingId=uuid]": [51,[3,4,6,9],[,5]],
		"/(authedOnly)/(admin)/admin/docusign-sender-redirect": [53,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/accounts": [54,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/accounts/[id=uuid]": [55,[3,4,10,11],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/calendly": [56,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/cch": [57,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/cch/oauth-redirect": [58,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/celery": [59,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/documents": [60,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/emails": [61,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/emails/sendgrid-import-contacts-job-status": [62,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/environment": [63,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions": [64,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions/500-module-error": [65,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions/500-rendering-error": [66,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions/csp-error": [67,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions/load-django-response": [68,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions/module-error": [69,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/exceptions/rendering-error": [70,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/permissions": [71,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/permissions/authentication-failure": [72,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/permissions/permission-failure": [73,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/run-one-off-command": [74,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/stripe-sessions": [75,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/engineering/sureprep": [76,[3,4,10],[,5]],
		"/(authedOnly)/(admin)/admin/entities": [77,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/memberships": [78,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/onboarding": [79,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/strategies-summary": [81,[3,4,12],[,5]],
		"/(authedOnly)/(admin)/admin/strategies-summary/by-strategy": [82,[3,4,12],[,5]],
		"/(authedOnly)/(admin)/admin/strategies": [80,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/tax-filing": [83,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/tax-filing/burndown": [84,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/tickets": [85,[3,4],[,5]],
		"/(authedOnly)/(admin)/admin/[...path]": [34,[3,4],[,5]],
		"/(anyAuth)/change-email-confirm": [26,[2]],
		"/(anyAuth)/change-email-confirm/success": [27,[2]],
		"/(authedOnly)/(client)/documents": [88,[3,13,15],[,14]],
		"/(anyAuth)/docusign-signer-redirect/[taskId=uuid]": [28,[2]],
		"/(anyAuth)/external-signing/[taskId=uuid]": [29,[2]],
		"/(anyAuth)/external-signing/[taskId=uuid]/complete": [30,[2]],
		"/(unauthedOnly)/forgot-password": [121,[23,24]],
		"/(unauthedOnly)/forgot-password/success": [122,[23,24]],
		"/(unauthedOnly)/link-existing-account": [123,[23]],
		"/(unauthedOnly)/login": [124,[23]],
		"/(authedOnly)/(client)/membership": [89,[3,13,16],[,14]],
		"/(authedOnly)/(client)/membership/[membershipId=uuid]/done": [90,[3,13,16],[,14]],
		"/(authedOnly)/(client)/membership/[membershipId=uuid]/legal-agreement": [91,[3]],
		"/(authedOnly)/(client)/membership/[membershipId=uuid]/payment-success": [92,[3,13,16],[,14]],
		"/(authedOnly)/(client)/membership/[membershipId=uuid]/sign": [93,[3,13,16],[,14]],
		"/(authedOnly)/onboarding/awaiting-access": [118,[3,21]],
		"/(authedOnly)/onboarding/(stepsHeader)/existing-plan": [115,[3,21,22]],
		"/(authedOnly)/onboarding/finish-setting-up-account": [119,[3]],
		"/(authedOnly)/onboarding/incomplete": [120,[3,21]],
		"/(authedOnly)/onboarding/(stepsHeader)/schedule-call": [116,[3,21,22]],
		"/(authedOnly)/onboarding/(stepsHeader)/welcome": [117,[3,21,22]],
		"/(anyAuth)/privacy-policy": [31,[2]],
		"/(authedOnly)/(client)/profile": [94,[3,13],[,14]],
		"/(unauthedOnly)/reset-password": [125,[23]],
		"/(unauthedOnly)/reset-password/success": [126,[23]],
		"/(authedOnly)/(client)/settings": [95,[3,13],[,14]],
		"/(unauthedOnly)/signup": [127,[23]],
		"/(authedOnly)/(client)/strategies": [96,[3,13],[,14]],
		"/(authedOnly)/(client)/strategies/[year=integer]": [97,[3,13],[,14]],
		"/(authedOnly)/(client)/strategies/[year=integer]/[slug]/[strategyId=uuid]": [98,[3,13],[,14]],
		"/(authedOnly)/(client)/tax-filing": [99,[3,13,17],[,14]],
		"/(authedOnly)/(client)/tax-filing/get-started": [103,[3,13,17],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/done": [104,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/e-sign": [105,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/extension-filing-success": [106,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/final-payment-success": [107,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/pay": [108,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/review": [109,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/sign/[taskId=uuid]/signer-redirect": [110,[3,13,19],[,14]],
		"/(authedOnly)/(client)/tax-filing/[year=integer]": [100,[3,13,17,18],[,14]],
		"/(authedOnly)/(client)/tax-filing/[year=integer]/get-started": [102,[3,13,17,18],[,14]],
		"/(authedOnly)/(client)/tax-filing/[year=integer]/[taxFilingId=uuid]": [101,[3,13,17,18],[,14]],
		"/(authedOnly)/(client)/tax-plan": [111,[3,13,20],[,14]],
		"/(authedOnly)/(client)/tax-plan/[year=integer]": [112,[3,13,20],[,14]],
		"/(authedOnly)/(client)/tax-plan/[year=integer]/quarterly-estimated-payments/[strategyId=uuid]": [114,[3,13,20],[,14]],
		"/(authedOnly)/(client)/tax-plan/[year=integer]/[slug]/[strategyId=uuid]": [113,[3,13,20],[,14]],
		"/(anyAuth)/terms-of-use": [32,[2]],
		"/(unauthedOnly)/verify/device-selection": [129,[23,25]],
		"/(unauthedOnly)/verify/device/[device=deviceName]": [128,[23,25]],
		"/(authedOnly)/(client)/[...path]": [87,[3,13],[,14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';